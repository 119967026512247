<template>
  <div
    class="sticky top-0 z-40 w-full transition duration-150"
    :class="{
      'md:bg-white': !isHomepage,
      'dark -mb-32 lg:-mb-28': isHomepage,
      'bg-primary': overlayMenuOpen
    }"
  >
    <div
      class="container flex justify-between bg-transparent py-5 transition-all lg:items-center"
      :class="isScrolled ? 'lg:py-5' : 'lg:py-7'"
    >
      <div class="flex items-center gap-x-7">
        <NuxtLink :to="{name: 'index'}">
          <UiLogo />
        </NuxtLink>
        <AppMenu v-if="!isHomepage" />
      </div>
      <button
        v-body-scroll-lock="overlayMenuOpen"
        class="bg-primary flex w-14 appearance-none items-center justify-center text-white lg:hidden"
        @click="toggleMenu"
      >
        <UiIcon :name="overlayMenuOpen ? 'pepicons-pop:times' : 'solar:hamburger-menu-linear'" />
      </button>
      <div class="relative hidden items-center gap-5 lg:flex">
        <AppMenuLanguageSwitcher />
        <div class="relative flex items-center gap-3.5">
          <UiButton
            :label="$t('global.customer_service_button')"
            type="tertiary"
            :to="$t('global.customer_service_button_url')"
          />
          <UiButton
            :label="$t('global.activate_wifi_button')"
            type="primary"
            @click="showPopUpMenu"
          />
          <UiPopUp v-show="popUpVisible" />
        </div>
      </div>
    </div>
    <AppMenuMobile :class="overlayMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'" />
  </div>
</template>

<script setup lang="ts">
import {vBodyScrollLock} from '@directives/body-lock';

defineProps<{
  isHomepage?: boolean
}>();

const {y} = useWindowScroll();
const isScrolled = computed(() => y.value > 0);

const overlayMenuOpen = ref(false);
const popUpVisible = ref(false);
const currentLang = ref('NL');

function toggleMenu() {
  overlayMenuOpen.value = !overlayMenuOpen.value;
}

const showPopUpMenu = () => {
  popUpVisible.value = !popUpVisible.value;
};

const switchLang = (lang: string) => {
  currentLang.value = lang;
};
</script>

<style scoped>
.slide-enter-active {
  animation: slide-in 0.5s ease-out;
}

.slide-leave-active {
  animation: slide-out 0.5s ease-in forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0)
  }

  to {
    transform: translateX(100vw)
  }
}
</style>
